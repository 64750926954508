<template>
  <div
    v-if="link"
    class="paragraph-button"
    :class="{
      'my-20': !parentType,
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <ButtonStyle
      v-if="link"
      v-slot="{ classes }"
      :color="color"
      :inverted="!options.isPrimary"
    >
      <FieldItemTypeLink
        v-bind="link"
        :class="classes"
        :title="link.title"
        has-icon
      />
    </ButtonStyle>
  </div>
</template>

<script lang="ts" setup>
import { ParagraphButtonFragment, SiteColor } from '#graphql-operations'

defineProps<{
  link?: ParagraphButtonFragment['link']
}>()

const { options, parentType, isEditing } = defineBlokkli({
  bundle: 'button',
  globalOptions: ['isHidden'],
  options: {
    color: {
      type: 'radios',
      label: 'Farbe',
      default: 'GREEN_ALT',
      displayAs: 'colors',
      options: {
        GREEN_ALT: { label: 'Grün', hex: '#68ab44' },
        BERRY: { label: 'Berry', hex: '#8b106f' },
        ORANGE: { label: 'Orange', hex: '#d58925' },
      },
    },
    isPrimary: {
      type: 'checkbox',
      label: 'Als Primärstil anzeigen',
      default: true,
    },
  },

  editor: {
    getDraggableElement: (el) => el.querySelector('a'),
  },
})

const color = computed(() => {
  switch (options.value.color) {
    case 'BERRY':
      return SiteColor.BERRY
    case 'ORANGE':
      return SiteColor.ORANGE
  }

  return SiteColor.GREEN_ALT
})
</script>
