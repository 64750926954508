<template>
  <ul
    class="text-base my-10 paragraph paragraph-links max-w-prose"
    :class="{
      'lg:columns-2 lg:gap-30': hasTwoColumns,
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <li v-for="link in links" class="leading-tight">
      <FieldItemTypeLink
        v-if="link"
        v-bind="link"
        class="link inline-flex mb-10"
        :class="{ 'is-white': isInverted, 'opacity-10': !link.uri?.path }"
        has-icon
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { ParagraphLinksFragment, SiteColor } from '#graphql-operations'

// Provided by parent paragraphs.
const background = inject<ComputedRef<SiteColor | undefined> | null>(
  'background',
  null,
)
const linkColumns = inject<ComputedRef<number> | null>('linkColumns', null)

const { isEditing, options } = defineBlokkli({
  bundle: 'links',
  globalOptions: ['isHidden'],
})

const props = defineProps<{
  links?: ParagraphLinksFragment['links']
}>()

const isInverted = computed(() => {
  return background && background.value === SiteColor.BERRY
})

const hasTwoColumns = computed(() => {
  if (linkColumns === null) {
    return (props.links?.length || 0) > 6
  }

  return linkColumns.value === 2
})
</script>
