<template>
  <div
    v-if="youtubeId"
    :id="options.anchorId"
    class="max-w-prose my-20"
    :class="{
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <div
      class="w-full aspect-video relative flex flex-col items-center justify-center overflow-hidden"
    >
      <iframe
        v-if="isPlaying"
        ref="video"
        :src="videoSrc"
        :title="caption || ''"
        class="w-full aspect-video absolute top-0 left-0"
        :class="{ 'z-10': isPlaying }"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />

      <MediaImage
        v-if="video?.thumbnail"
        :image="video?.thumbnail"
        :image-style="imageStyle"
        class="absolute top-0 right-0 w-full h-full"
        :class="isPlaying ? 'opacity-0 z-0' : 'opacity-1'"
      />
      <div v-else>
        <img :src="youtubeThumbnailUrl" />
      </div>

      <button
        v-if="!isPlaying"
        class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
        @click="isPlaying = true"
      >
        <IconsPlayButton />
      </button>
    </div>
    <h4
      v-if="caption"
      v-blokkli-editable:field_caption
      class="uppercase text-xs md:text-base"
    >
      {{ caption }}
    </h4>
  </div>
</template>

<script lang="ts" setup>
import { getYouTubeID } from '~/helpers/getYouTubeID'
import { ParagraphVideoRemoteFragment } from '#graphql-operations'

const { options, isEditing } = defineBlokkli({
  bundle: 'video_remote',
  globalOptions: ['anchorId', 'isHidden'],
})

const props = defineProps<{
  video?: ParagraphVideoRemoteFragment['video']
  caption?: ParagraphVideoRemoteFragment['caption']
}>()

const isPlaying = ref(false)
const youtubeId = computed(() => getYouTubeID(props.video?.url || ''))
const youtubeThumbnailUrl = computed(() => {
  if (youtubeId.value) {
    return `https://i3.ytimg.com/vi/${youtubeId.value}/maxresdefault.jpg`
  }
  return ''
})

const videoSrc = computed(
  () =>
    `https://www.youtube.com/embed/${youtubeId.value}?modestbranding=1&rel=0&autoplay=1`,
)

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    sm: 400,
    md: 768,
    lg: 646,
    xl: 770,
  },
})
</script>
