<template>
  <div
    v-if="structuredTable"
    class="my-20 max-w-prose"
    :class="{
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <StructuredTable
      v-bind="structuredTable"
      :mobile-style="options.mobileStyle"
      :has-stripes="parentType !== 'accordion_element'"
    />
  </div>
</template>

<script lang="ts" setup>
import { ParagraphTableMarkupFragment } from '#graphql-operations'

const { options, parentType, isEditing } = defineBlokkli({
  bundle: 'table_markup',
  globalOptions: ['isHidden'],
  options: {
    mobileStyle: {
      type: 'radios',
      label: 'Mobile Darstellung',
      default: 'vertical',
      options: {
        vertical: 'Vertikal',
        horizontal: 'Horizontal',
      },
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

defineProps<{
  structuredTable: ParagraphTableMarkupFragment['structuredTable']
}>()
</script>
