<template>
  <div
    v-if="parentType"
    class="mb-30"
    :class="{
      wrapperClasses,
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <DrupalLink
      :to="link?.uri?.path"
      class="box box-padding group hyphens-auto lg:min-h-[308px] xl:min-h-[370px]"
      :class="classes"
    >
      <div class="flex flex-col md:flex-row h-full mb-20 z-10">
        <div
          v-if="icon"
          v-blokkli-droppable:field_icon
          class="pointer-events-none overflow-hidden w-full md:w-2/5 md:order-2 flex justify-center"
        >
          <MediaIcon
            v-bind="icon"
            class="opacity-40 w-full h-[150px] md:h-full mb-40 md:mb-0 md:p-20"
          />
        </div>
        <div class="w-full" :class="{ 'md:w-3/5': icon }">
          <h2
            v-if="title"
            v-blokkli-editable:field_title
            class="font-semibold text-xl md:text-3xl xl:text-4xl mb-10"
          >
            {{ title }}
          </h2>
          <h3
            v-if="lead"
            v-blokkli-editable:field_lead
            class="text-lg md:text-md mb-20 md:mb-30 xl:text-xl"
          >
            {{ lead }}
          </h3>
          <p
            v-if="text"
            v-blokkli-editable:field_text
            class="text-sm md:text-base"
            :class="{ 'mobile-only:hidden': options.hideTextOnMobile }"
          >
            {{ text }}
          </p>
        </div>
      </div>
      <div
        v-if="linkText"
        v-blokkli-editable:field_label
        class="box-padding py-0 box-pull-down absolute bottom-0 left-0"
      >
        <ButtonStyle v-slot="{ classes: buttonClasses }" :color="buttonColor">
          <div :class="buttonClasses">
            <SpriteSymbol name="arrow" class="h-10 w-20 mr-10" />
            <span>{{ linkText }}</span>
          </div>
        </ButtonStyle>
      </div>
    </DrupalLink>
  </div>
  <div
    v-else
    :class="{
      wrapperClasses,
      'bk-paragraph-region-affected': options.isHidden,
    }"
    class="mb-30"
  >
    <DrupalLink
      :to="link?.uri?.path"
      class="box group hyphens-auto"
      :class="classes"
    >
      <div
        class="box-padding flex flex-col md:flex-row h-full mb-20 z-10 overflow-hidden relative"
      >
        <div class="w-full" :class="{ 'md:w-3/5': icon }">
          <h2
            v-if="title"
            v-blokkli-editable:field_title
            class="font-semibold text-xl mb-5"
          >
            {{ title }}
          </h2>
          <h3 v-if="lead" v-blokkli-editable:field_lead class="text-md mb-20">
            {{ lead }}
          </h3>
          <p
            v-if="text"
            v-blokkli-editable:field_text
            class="text-sm md:text-base"
            :class="{ 'mobile-only:hidden': options.hideTextOnMobile }"
          >
            {{ text }}
          </p>
        </div>
        <div
          v-if="icon"
          v-blokkli-droppable:field_icon
          class="pointer-events-none overflow-hidden absolute top-1/2 -right-30 -translate-y-1/2 md:rotate-12"
        >
          <MediaIcon
            v-bind="icon"
            class="opacity-40 w-200 md:w-[240px] aspect-square"
          />
        </div>
      </div>
      <div
        v-if="linkText"
        v-blokkli-editable:field_label
        class="box-padding py-0 box-pull-down absolute bottom-0 left-0"
      >
        <ButtonStyle v-slot="{ classes: buttonClasses }" :color="buttonColor">
          <div :class="buttonClasses">
            <SpriteSymbol name="arrow" class="h-10 w-20 mr-10" />
            <span>{{ linkText }}</span>
          </div>
        </ButtonStyle>
      </div>
    </DrupalLink>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphTeaserBoxFragment, SiteColor } from '#graphql-operations'
import { getBoxClasses, getComplementaryColor } from '~/helpers/siteColor'

const { options, parentType, isEditing } = defineBlokkli({
  bundle: 'teaser_box',
  globalOptions: ['teaserColor', 'isHidden'],
  editor: {
    previewWidth: 770,
  },
  options: {
    hideTextOnMobile: {
      type: 'checkbox',
      label: 'Text auf Mobile verstecken',
      default: false,
    },
  },
})

const props = defineProps<{
  title: string
  lead?: string
  text?: string
  link: ParagraphTeaserBoxFragment['link']
  linkText: string
  icon: ParagraphTeaserBoxFragment['icon']
}>()

const classes = computed(() => {
  return getBoxClasses(options.value.teaserColor as SiteColor)[0][0]
})
const wrapperClasses = computed(() => {
  if (props.icon) {
    return 'col-span-full md:col-span-2'
  }
  return 'col-span-full md:col-span-1'
})

const buttonColor = computed(() => {
  return getComplementaryColor(options.value.teaserColor as SiteColor)
})
</script>

<style lang="postcss">
:not(.paragrpah-teaser-box) + .paragrpah-teaser-box {
  @apply col-start-1;
}
</style>
