<template>
  <Accordion
    :title="title"
    :anchor="anchor"
    class="mt-20 md:mt-30 lg:mt-40"
    :always-open="isEditing"
    :class="{
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <template #title>
      <span v-blokkli-editable:field_title>{{ title }}</span>
    </template>
    <BlokkliField :list="paragraphs" name="field_accordion_paragraph" />
  </Accordion>
</template>

<script lang="ts" setup>
import { ParagraphAccordionElementFragment } from '#graphql-operations'

const { isEditing, options } = defineBlokkli({
  bundle: 'accordion_element',
  globalOptions: ['isHidden'],
})

defineProps<{
  title: string
  anchor?: string
  paragraphs: ParagraphAccordionElementFragment['paragraphs']
}>()
</script>

<style lang="postcss"></style>
