<template>
  <div
    :class="{
      margin,
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <BlokkliField
      :list="paragraphs"
      name="field_paragraphs"
      non-empty-class="grid gap-20 lg:gap-30 md:grid-cols-2 lg:grid-cols-3"
    />
  </div>
</template>

<script lang="ts" setup>
import { ParagraphGridFragment } from '#graphql-operations'

const { options, isEditing } = defineBlokkli({
  bundle: 'grid',
  globalOptions: ['marginTop', 'isHidden'],
  editor: {
    disableEdit: true,
  },
})

defineProps<{
  paragraphs: ParagraphGridFragment['paragraphs']
}>()

const margin = computed(() => {
  switch (options.value.marginTop) {
    case 'huge':
      return 'my-40 lg:my-70'
    case 'large':
      return 'my-30 lg:my-50'
    case 'normal':
      return 'my-30 lg:my-40'
  }
  return 'my-20'
})
</script>
